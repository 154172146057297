import { withDependencies, optional, named } from '@wix/thunderbolt-ioc'
import { SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { CustomUrlMapperSymbol, ICustomUrlMapper } from 'feature-custom-url-mapper'
import { IRoutingSyncMiddleware, IRoutingConfig, CandidateRouteInfo } from './types'
import { name } from './symbols'

const customUrlMiddleware = (
	customUrlMapper: ICustomUrlMapper,
	routingConfig: IRoutingConfig
): IRoutingSyncMiddleware => ({
	handleSync: (routeInfo: CandidateRouteInfo) => {
		const { routes, isWixSite }: { routes: IRoutingConfig['routes']; isWixSite: boolean } = routingConfig

		// If the routeInfo has a type it means that this is a familiar Static or Dynamic route.
		if (routeInfo.type) {
			return routeInfo
		}

		const customRoute = customUrlMapper?.getUrlPageRoute(routes, routeInfo.relativeUrl)
		const mainPageRoute = './'
		const isPageLink = ['https:', 'http:'].includes(routeInfo.parsedUrl.protocol)
		const defaultRoute = isWixSite && isPageLink && mainPageRoute

		const currentRoute = customRoute ?? defaultRoute
		return { ...routeInfo, ...routes[currentRoute as string] }
	},
})

export const CustomUrlMiddleware = withDependencies(
	[optional(CustomUrlMapperSymbol), named(SiteFeatureConfigSymbol, name)],
	customUrlMiddleware
)
