import { IWarmupDataProvider, WarmupDataProviderSymbol } from 'feature-warmup-data'
import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	FeatureStateSymbol,
	ILanguage,
	IRendererPropsExtender,
	ITranslate,
	ITranslationsFetcher,
	LanguageSymbol,
	Translate,
} from '@wix/thunderbolt-symbols'
import { getTranslation } from './translationsUrl'
import { TranslationsFeatureState, TranslationsWarmupData } from './types'
import { IFeatureState } from 'thunderbolt-feature-state'

const translationsImpl = (
	{ userLanguage }: ILanguage,
	featureState: IFeatureState<TranslationsFeatureState>,
	warmupDataProvider: IWarmupDataProvider
): (() => Promise<ITranslate>) => {
	return async () => {
		if (!featureState.get()?.translations) {
			featureState.update(() => ({
				translations: warmupDataProvider
					.getWarmupData<TranslationsWarmupData>('translations')
					.then((translations) => translations || getTranslation(userLanguage)),
			}))
		}

		const translations = await featureState.get().translations

		return (featureNamespace, key, defaultValue) =>
			(translations[featureNamespace] && translations[featureNamespace][key]) || defaultValue
	}
}

const translateBinder = (translationsFetcher: ITranslationsFetcher): IRendererPropsExtender => ({
	async extendRendererProps() {
		return { translate: await translationsFetcher() }
	},
})

export const TranslationsImpl = withDependencies(
	[LanguageSymbol, named(FeatureStateSymbol, 'translations'), WarmupDataProviderSymbol],
	translationsImpl
)
export const TranslateBinder = withDependencies([Translate], translateBinder)
