import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LifeCycle, contextIdSymbol } from '@wix/thunderbolt-symbols'
import { LogicalReflectorSymbol, PageProviderSymbol, PageStructureJsonSymbol, PagePropsJsonSymbol } from './symbols'
import { IPageReflector, IPageProvider } from './types'
import { PageProvider } from './PageReflector'
import { LogicalReflector, LogicalReflectorDs } from './logicalReflector'
import PageBiReporting from './pageBiReporting'

export const site: ContainerModuleLoader = (bind) => {
	bind<IPageProvider>(PageProviderSymbol).toProvider<IPageReflector>(PageProvider)
	bind(LogicalReflectorSymbol).toProvider<IPageReflector>(LogicalReflector)
	bind(LifeCycle.AppDidMountHandler).to(PageBiReporting)
}

export const editor: ContainerModuleLoader = (bind) => {
	bind<IPageProvider>(PageProviderSymbol).toProvider<IPageReflector>(LogicalReflectorDs)
	bind(LogicalReflectorSymbol).toProvider<IPageReflector>(LogicalReflectorDs)
	// TODO: #TB-3565
	bind<string>(contextIdSymbol).toConstantValue('editor')
}

export {
	PageProviderSymbol,
	LogicalReflectorSymbol,
	PageStructureJsonSymbol,
	PagePropsJsonSymbol,
	IPageProvider,
	IPageReflector,
}
