import { IStructureStore, Structure, IBaseStructureAPI, IStructureAPI } from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { BaseStructureAPISym } from './symbols'

const dsStructureAPI = (structureStore: IStructureStore, baseStructureAPI: IBaseStructureAPI): IStructureAPI => {
	return {
		...baseStructureAPI,
		addPageAndRootToRenderedTree: (pageId: string) => {
			const sitePages = structureStore.get('SITE_PAGES')
			structureStore.update({
				SITE_PAGES: {
					...sitePages,
					components: [pageId],
				},
			})
		},
		addShellStructure: async () => Promise.resolve(),
		loadPageStructure: async () => Promise.resolve({}),
	}
}

export const DsStructureAPI = withDependencies([Structure, BaseStructureAPISym], dsStructureAPI)
